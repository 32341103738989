import React, {useEffect, useRef} from "react"
import {graphql} from "gatsby"

import Index from "../components/layout"
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import {gsap, Power3, TweenLite} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const query = graphql`
query {
  about: strapiAbout {
    content
    image {
      childImageSharp {
        fluid {
           ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
}
`

const About = ({ data }) => {

    const title = 'About - The man behind the Rock';
    const description = "Learn more about the man behind the scene";

    const about = data.about;

    let aboutPageImage = useRef(null);
    let aboutPageText = useRef(null);

    useEffect(() => {

        TweenLite.from( aboutPageImage, .6, {
            x: -140,
            // opacity: 0,
            ease: Power3.easeOut,
        });

        TweenLite.from( aboutPageText, 1.2, {
            opacity: 0,
            ease: Power3.easeOut,
        });

    },[aboutPageImage,])

    return (
        <Index
            title={title}
            description={description}
        >
            <div className="narrow-section about-wrapper">
                <section ref={el => (aboutPageImage = el)} className="about-img-wrapper">
                    <div className="about-img-box">
                        <Img className="main-img"
                             fluid={about.image.childImageSharp.fluid}
                             alt={'About Rockaholik'}
                        />
                    </div>
                </section>

                <section ref={el => (aboutPageText = el)} className="blog-content">
                    <ReactMarkdown source={about.content} />
                </section>
            </div>
        </Index>
    )
}

export default About
